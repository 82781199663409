.facebook, .instagram {
	font-size: 32px;
}
.facebook {
	margin: 0 .5em;
}
.instagram {
	margin-right: .5em;
}
.desc {
	// max-width: 600px;
	// margin-right: 1em;
}
.form {
	max-width: 500px;
	margin-top: 1.5em;
}

:global .valid-feedback {
	display: block !important;
}
.invalid {
	display: block !important;
}