.untappd {
	color: white;
	padding: 3rem 0;
	font-size: 24px;
	background-size: cover;
	table {
		tbody {
			tr {
				td {
					color: white;
				}
			}
		}
	}
}