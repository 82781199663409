.GlobalNav {
	left: 25vw;
	min-width: 40vw;
	position: absolute;
	top: 20vh;
	z-index: 1;
	&.sticky {
		left: 60px;
		position: fixed;
		top: 0;
		z-index: 999;
		&::before {
			background-color: black;
			border-bottom: 1px solid rgba(0, 0, 0, 0.45);
			box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 10px -10px;
			content: '';
			height: 61px;
			left: -2000px;
			position: absolute;
			width: 4000px;
			z-index: -1;
		}
		ul {
			li {
				font-size: 1.5rem;
			}
		}
		:local .logo {
			left: 20px;
			opacity: 1;
			transform: translateY(0);
			transition: all .25s ease-in;
			visibility: visible;
		}
	}
	.logo {
		// position: fixed;
		left: -10px;
		opacity: 0;
		top: 0;
		visibility: hidden;
		z-index: 9999;
		img {
			max-width: 45px;
			width: 100%;
		}
	}
	ul {
		align-items: center;
		display: flex;
		list-style: none;
		margin: 0;
		overflow: hidden;
		padding: 0;
		width: 100%;
		li {
			color: white;
			float: left;
			font-size: 3rem;
			font-weight: bold;
			margin-left: 20px;
			text-transform: uppercase;
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	:global .sticky-outer-wrapper {
		width: 100%;
		&.active {
			:local .para {
				transform: unset !important;
			}
		}

		&.active {
			position: static;
			top: 0;
			.sticky-inner-wrapper {
				left: 60px;
				position: fixed !important;
				top: 0 !important;
				&::before {
					background-color: black;
					border-bottom: 1px solid rgba(0, 0, 0, 0.45);
					box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 10px -10px;
					content: '';
					height: 61px;
					left: -2000px;
					position: absolute;
					width: 4000px;
					z-index: -1;
				}
				ul {
					li {
						font-size: 1.5rem;
					}
				}
				:local .logo {
					left: 20px;
					opacity: 1;
					transform: translateY(0);
					transition: all .25s ease-in;
					visibility: visible;
				}
			}
		}
		.sticky-inner-wrapper {
			align-items: center;
			display: flex;
			position: static !important;
			top: -56px !important;
			transition: top 0.25s ease-in-out;
			width: 100%;
		}
	}
}
