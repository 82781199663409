@import '../../styles/variables.scss';
.hero {
	background-color: #000;
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100vw;
	&.comingsoon {
		align-items: center;
		justify-content: center;
		margin: 0;
		.list {
			margin: 0;
			h1 {
				font-size: 24px;
			}
		}
		@include media-breakpoint-up(sm) {
			.list {
				h1 {
					font-size: calc(1.625rem + 4.5vw);
				}
			}
		}
	}
	.list {
		color: white;
		display: flex;
		justify-content: center;
		margin: 5vw;
		.listinner {
			max-width: 95%;
		}
		h1 {
			font-size: calc(1.625rem + 2vw);
			span {
				color: #f28e1c;
				text-transform: uppercase;
			}
		}
	}
	.logo {
		max-width: 60vw;
		width: 100%;
		height: auto;
		// margin-left: 5vw;
		margin-top: 5vw;
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
	}
	.nav {
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		left: 40vw;
		top: 30vh;
		li {
			font-size: 32px;
			font-weight: 700;
			float: left;
			color: white;
			margin-right: 20px;
		}
	}
}
	.bubblecont {
		position: relative;
	}

:global .beaker {
	// padding: 100px 0;'
	height: fit-content;
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	.bubble {
		width: 4.5vw;
		height: 4.5vw;
		border: 2px solid;
		border-radius: 200px;
		position: absolute;
		border-color: white;
		z-index: 0;
	}

	.x1 {
		left: 10%;
		transform: scale(0.8);
		opacity: 0.4;
		& :local {
			animation: moveclouds 8s linear infinite,
				sideWays 3s ease-in-out infinite alternate;
		}
	}

	.x2 {
		left: 45%;
		transform: scale(0.5);
		opacity: 0.9;
		& :local {
			animation: moveclouds 15s linear infinite,
				sideWays 5s ease-in-out infinite alternate;
		}
	}
	.x3 {
		left: 20%;
		transform: scale(0.7);
		opacity: 0.6;
		& :local {
			animation: moveclouds 10s linear infinite,
				sideWays 4s ease-in-out infinite alternate;
		}
	}
	.x4 {
		left: 30%;
		transform: scale(0.65);
		opacity: 0.7;
		& :local {
			animation: moveclouds 18s linear infinite,
				sideWays 2s ease-in-out infinite alternate;
		}
	}
	.x5 {
		left: 60%;
		transform: scale(0.7);
		opacity: 0.8;
		& :local {
			animation: moveclouds 7s linear infinite,
				sideWays 1s ease-in-out infinite alternate;
		}
	}
}
@keyframes moveclouds {
	0% {
		top: 80%;
	}
	55% {
		left: 45%;
	}
	100% {
		top: -10%;
		left: 65%;
	}
}

@keyframes sideWays {
	0% {
		margin-left: 0px;
	}
	100% {
		margin-left: 35px;
	}
}

@include media-breakpoint-up(lg) {
	.hero {
		flex-direction: row;
		.logo {
			// max-width: 20vw;
		}
		.list {
			margin-top: 20vh;
			max-width: 42vw;
			h1 {
				font-size: calc(1.625rem + 1vw);
			}
		}
	}
	:global .beaker {
		// padding: 100px 0;'
		display: flex;
		height: fit-content;
		justify-content: start;
		margin-left: 5vw;
		max-width: 40vw;
		position: relative;
		width: 100%;
		.x1 {
			left: 10%;
			transform: scale(0.5);
		}

		.x2 {
			left: 45%;
			transform: scale(0.2);
		}
		.x3 {
			left: 20%;
			transform: scale(0.4);
		}
		.x4 {
			left: 30%;
			transform: scale(0.35);
		}
		.x5 {
			left: 55%;
			transform: scale(0.4);
		}
	}
}


@include media-breakpoint-up(xl) {
	.hero {
		.list {
			margin-top: 20vh;
			max-width: 55vw;
			h1 {
				font-size: calc(1.625rem + 3vw);
			}
		}
	}
	:global .beaker {
		max-width: 20vw;
		.bubble {
			width: 4.5vw;
			height: 4.5vw;
			border: 6px solid;
			border-radius: 200px;
			position: absolute;
			border-color: white;
			z-index: 0;
		}
	}
}