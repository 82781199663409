@import 'bootstrap-icons/font/bootstrap-icons.css';
@import './variables.scss';

@import "~bootstrap/scss/bootstrap";

html, body, #root, .app {
	// scroll-behavior: smooth;
	height: 100%;
}

body {
	background-color: $light;
	font-family: 'Archivo Narrow', sans-serif;
}

