$primary: #f28e1c;

@import "~bootstrap/scss/functions";
// $theme-colors: (
// 	'primary': $brand-primary,
// 	'secondary': $brand-secondary
// );
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.foo {
	color: purple;
}